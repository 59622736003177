import { createMutationHook, useApiQuery } from 'queries/common';
import { createQueryKeyFactory } from 'queries/common/dependencyBuilder';

import internalAPI from '../../helpers/internalAPI';

export const useUserLinkedAccounts = () => {
  const queryKey = createQueryKeyFactory([
    { resource: 'user_account' },
    { resource: 'oauth_providers', id: 'list' },
  ]);

  const query = useApiQuery(queryKey, () =>
    internalAPI.get('/internal_api/v1/oauth_providers'),
  );

  return {
    data: query.data?.data ?? [],
    errors: query.error,
    isLoading: query.isLoading,
  };
};

export const useAlternativeEmails = () => {
  const queryKey = createQueryKeyFactory([{ resource: 'alternative_emails' }]);

  const query = useApiQuery([...queryKey, 'list', {}], () =>
    internalAPI.get('/internal_api/v1/alternative_emails'),
  );

  return {
    data: query.data?.data?.data ?? [],
    errors: query.error,
    isLoading: query.isLoading,
    refetch: query.refetch,
  };
};

const resendVerificationEmail = (body) =>
  internalAPI.post(
    `/internal_api/v1/alternative_emails/${body.id}/resend`,
    body,
  );

export const useResendVerificationEmail = createMutationHook(
  resendVerificationEmail,
);

const createAlternativeEmail = (body) =>
  internalAPI.post('/internal_api/v1/alternative_emails', body);

export const useCreateAlternativeEmail = createMutationHook(
  createAlternativeEmail,
);

const deleteAlternativeEmail = (id) =>
  internalAPI.delete(`/internal_api/v1/alternative_emails/${id}`);

export const useDeleteAlternativeEmail = createMutationHook(
  deleteAlternativeEmail,
);
