import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { matchRoutes, useLocation } from 'react-router';

import ErrorBoundary from 'components/common/ErrorBoundary';
import InternalToolbar from 'components/internalToolbar';
import OnboardingHeader from 'components/layout/LayoutHeader/OnboardingHeader';

import Footer from './Footer';

const NavigationV2 = loadable(() => import('components/layout/NavigationV2'));

const FullPageLayout = ({ children }) => {
  const location = useLocation();
  const noFooterRoutes = matchRoutes(
    [{ path: '/org/:id/incidents/:id/*' }, { path: '/incidents/:id/*' }],
    location,
  );
  const noNavRoutes = matchRoutes(
    [
      { path: '/' },
      { path: '/registrations/new' },
      { path: '/sessions/new' },
      { path: '/sessions/sso/new' },
    ],
    location,
  );

  let boxProps = {
    ml: 6,
    mr: 4,
    w: '100%',
    maxW: '1800px',
    px: 4,
    py: 3,
    m: 0,
  };

  if (noFooterRoutes) {
    boxProps.pb = 0;
  }

  return (
    <>
      <InternalToolbar />
      <Flex minHeight="100vh" width="100%" flexDirection={'column'}>
        {!noNavRoutes && <NavigationV2 />}

        <OnboardingHeader pathname={location.pathname} />

        <Flex
          flex="1"
          direction="column"
          justifyContent="space-between"
          alignItems={'center'}
        >
          <Box flex="1" {...boxProps}>
            <ErrorBoundary>{children}</ErrorBoundary>
          </Box>
          {!noFooterRoutes && (
            <>
              <Flex w="100%" justifyContent="flex-end">
                <Footer />
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </>
  );
};

FullPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FullPageLayout;
