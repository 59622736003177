import firehydrantAPI from 'helpers/firehydrantAPI';
import { createMutationHook, useApiQuery } from 'queries/common';

export const SIGNAL_ESCALATION_POLICY_DETAIL =
  'SIGNAL_ESCALATION_POLICY_DETAIL';
export const SIGNAL_ESCALATION_POLICY_LIST = 'SIGNAL_ESCALATION_POLICY_LIST';

export const useEscalationPolicies = (teamId, params = {}, options) => {
  const key = [{ resource: SIGNAL_ESCALATION_POLICY_LIST }, 'list', params];
  const query = useApiQuery(
    key,
    () => firehydrantAPI.get(`teams/${teamId}/escalation_policies`, { params }),
    {
      enabled: Boolean(teamId),
      ...options,
    },
  );

  return {
    ...query,
    data: query.data?.data?.data ?? [],
    pagination: query.data?.data?.pagination ?? {},
  };
};

export const useEscalationPolicy = (teamId, policyId, params = {}, options) => {
  const query = useApiQuery(
    [SIGNAL_ESCALATION_POLICY_DETAIL, 'detail', policyId],
    () =>
      firehydrantAPI.get(`teams/${teamId}/escalation_policies/${policyId}`, {
        params,
      }),
    {
      enabled: Boolean(teamId && policyId),
      ...options,
    },
  );

  return {
    ...query,
    data: query.data?.data ?? {},
  };
};

const createEscalationPolicy = (body) =>
  firehydrantAPI.post(`teams/${body.teamId}/escalation_policies`, body);

export const useCreateEscalationPolicy = createMutationHook(
  createEscalationPolicy,
);

const updateEscalationPolicy = (body) =>
  firehydrantAPI.patch(
    `teams/${body.teamId}/escalation_policies/${body.id}`,
    body,
  );

export const useUpdateEscalationPolicy = createMutationHook(
  updateEscalationPolicy,
);

const deleteEscalationPolicy = (body) =>
  firehydrantAPI.delete(
    `teams/${body.teamId}/escalation_policies/${body.policyId}`,
  );

export const useDeleteEscalationPolicy = createMutationHook(
  deleteEscalationPolicy,
);
